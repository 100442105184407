<template>
  <div>
    <div class="page-container pb-0 mb-2">
      <filter-component
        @filter="handleFilter"
      />
    </div>
    <div class="page-container-table">
      <!--Phần header của bảng -->
      <button-all-header
        :hideDelete="false"
        :hideImportFile="false"
        :hideDowload="false"
        :hideAdd="false"
        :hideEyeOff="true"
        :hideEyeOn="true"
        :showEyeOff="showEye"
        :showEyeOn="showEye"
        @clickExportExcel="downloadExportFile"
        @clickShow="showIspector"
        @clickHide="hideIspector"
        @search="search($event)"
      />
      <!--Phần header của bảng -->
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: số hiệu văn bản-->
          <span v-if="props.column.field === 'code'">
            <b-badge variant="light-primary">
              {{ props.row.code }}
            </b-badge>
          </span>

          <span v-else-if="props.column.field === 'birthDay'">
            {{ props.row.birthDay | formatDateToDDMM }}
          </span>
          <span v-else-if="props.column.field === 'dateActive'">
            {{ props.row.dateActive | formatDateToDDMM }}
          </span>
          <span v-else-if="props.column.field === 'dateExpired'">
            {{ props.row.dateExpired | formatDateToDDMM }}
          </span>

          <span v-else-if="props.column.field === 'isShow'">
            <b-badge variant="light-primary">
              <feather-icon
                :icon="props.row.isShow ? 'CheckIcon' : 'XIcon' "
                size="18"
                class="text-body"
              />
            </b-badge>
          </span>

          <!-- Column:Chức năng -->
          <span v-else-if="props.column.field === 'ActionFunction'">
            <span
              class="ml-2"
              @click="handleHideShow(props.row)"
            >
              <feather-icon
                v-b-tooltip.hover.top="props.row.isShow ? 'Ẩn' : 'Hiện'"
                :icon="props.row.isShow ? 'EyeOffIcon' : 'EyeIcon'"
                size="18"
                class="text-body"
              />
            </span>
          </span>
        </template>
      </vue-good-table>

      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />
    </div>
  </div>

</template>

<script>
import {
  BBadge,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import FilterComponent from './component/FilterComponent.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constansts/ConstanstsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    FilterComponent,
    BBadge,
    BButton,
    VBTooltip,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      // ==dữ liệu bảng
      dataList: [],
      columns: [
        {
          label: 'MÃ KIỂM ĐỊNH VIÊN',
          field: 'code',
        },
        {
          label: 'TÊN KIỂM ĐỊNH VIÊN',
          field: 'name',
        },
        {
          label: 'DOANH NGHIỆP',
          field: 'bussiness',
        },
        {
          label: 'NGÀY SINH',
          field: 'birthDay',
        },
        {
          label: 'ĐỊA CHỈ',
          field: 'address',
        },
        {
          label: 'Số CMT/CCCD/HC',
          field: 'identityCardNumber',
        },
        {
          label: 'Số hiệu chứng chỉ',
          field: 'certificateNumber',
        },
        {
          label: 'NGÀY HIỆU LỰC',
          field: 'dateActive',
        },
        {
          label: 'NGÀY HẾT HẠN',
          field: 'dateExpired',
        },
        {
          label: 'ẨN/HIỆN',
          field: 'isShow',
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
      // ==phân trang
      urlQuery: {
        bussinessId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      totalRecord: 0,
      currentPage: 10,
      showEye: false,
      Ids: [],
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_INSPECTOR, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },

    // xử lý filter
    handleFilter(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessId = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessId = null
        this.fetchData(this.urlQuery)
      }
    },
    async handleHideShow(val) {
      const payload = {
        ids: [val.id],
        show: !val.isShow,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.put(ConstantsApi.HIDE_OR_SHOW_INSPECTOR, payload).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(val.isShow ? 'Ẩn kiểm định viên thành công' : 'Hiện kiểm định viên thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(val.isShow ? 'Ẩn kiểm định viên thành công' : 'Hiện kiểm định viên không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    async showIspector() {
      this.Ids = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      const payload = {
        ids: this.Ids,
        show: true,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.put(ConstantsApi.HIDE_OR_SHOW_INSPECTOR, payload).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Hiện kiểm định viên thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Hiện kiểm định viên không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    async hideIspector() {
      this.Ids = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      const payload = {
        ids: this.Ids,
        show: false,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.put(ConstantsApi.HIDE_OR_SHOW_INSPECTOR, payload).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Ẩn kiểm định viên thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Ẩn kiểm định viên không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showEye = selectedRows.length > 0
    },

    async downloadExportFile() {
      axiosApiInstance({
        url: '/Inspector/export-excel', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'KiemDinhVien.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>

<style>

</style>
