var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-container pb-0 mb-2"},[_c('filter-component',{on:{"filter":_vm.handleFilter}})],1),_c('div',{staticClass:"page-container-table"},[_c('button-all-header',{attrs:{"hideDelete":false,"hideImportFile":false,"hideDowload":false,"hideAdd":false,"hideEyeOff":true,"hideEyeOn":true,"showEyeOff":_vm.showEye,"showEyeOn":_vm.showEye},on:{"clickExportExcel":_vm.downloadExportFile,"clickShow":_vm.showIspector,"clickHide":_vm.hideIspector,"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'code')?_c('span',[_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(props.row.code)+" ")])],1):(props.column.field === 'birthDay')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateToDDMM")(props.row.birthDay))+" ")]):(props.column.field === 'dateActive')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateToDDMM")(props.row.dateActive))+" ")]):(props.column.field === 'dateExpired')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateToDDMM")(props.row.dateExpired))+" ")]):(props.column.field === 'isShow')?_c('span',[_c('b-badge',{attrs:{"variant":"light-primary"}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":props.row.isShow ? 'CheckIcon' : 'XIcon',"size":"18"}})],1)],1):(props.column.field === 'ActionFunction')?_c('span',[_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.handleHideShow(props.row)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(props.row.isShow ? 'Ẩn' : 'Hiện'),expression:"props.row.isShow ? 'Ẩn' : 'Hiện'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":props.row.isShow ? 'EyeOffIcon' : 'EyeIcon',"size":"18"}})],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.pageChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }