<template>
  <div id="filter-custom">
    <h3
      class="header"
    >
      Bộ lọc
    </h3>
    <b-row>
      <b-col
        md="4"
        xl="4"
      >
        <b-form-group>
          <label
            for="basicInput"
          >Doanh nghiệp<span class="required"></span></label>
          <v-select
            v-model="valueFilter"
            :reduce="label => label.id"
            label="name"
            :options="businessList"
            :placeholder="'Doanh nghiệp'"
            @input="filter"
          >
            <template v-slot:no-options="{ searching }">
              <template v-if="searching">
                Không có kết quả.
              </template>
              <em
                v-else
              >Không có dữ liệu</em>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constansts/ConstanstsApi'

export default {
  components: {

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    VSelect,

  },
  data() {
    return {
      businessList: [],
      valueFilter: '',
    }
  },

  created() {
    this.fetchCombobox()
  },

  methods: {

    async fetchCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_BUSINESS)
      this.businessList = data?.data
    },

    filter() {
      this.$emit('filter', this.valueFilter)
    },
  },
}
</script>

<style lang="scss">
#filter-custom{
  .header{
    font-size: 18px;
    color: #181F28;
  }
}
</style>
